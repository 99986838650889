import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "./modal";
import { faqsFields } from "../../helper/FaqsField";
import { DELETE, BROWSE, faqsActions } from "../../store/faqs-slice";
import AlertModal from "../../components/AlertModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";

export default function Faqs() {
  const header = ["Question", "Answer", "Action"];

  const { showModal, allFaqs, success, error, message } = useSelector(
    state => state.faqs
  );
  const dispatch = useDispatch();

  const form = useSelector(state => state.faqs.form);
  const edit = useSelector(state => state.faqs.edit);

  useEffect(() => {
    dispatch(BROWSE("All"));
  }, [dispatch, success]);

  const editFaq = (e, id) => {
    e.preventDefault();
    dispatch(faqsActions.setEdit(true));
    dispatch(faqsActions.setShowModal(true));

    const data = allFaqs.find(item => item.id === id);

    dispatch(faqsActions.fillForm(data));
  };

  const addFaq = e => {
    e.preventDefault();
    dispatch(faqsActions.setShowModal(true));
  };

  const handleDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(DELETE(id));
      }
    });
  };

  return (
    <>
      {success ? (
        <AlertModal
          icon={<FaCheck className="text-green-500 text-4xl" />}
          status={"Success"}
          message={message}
          button={"Okay"}
          actions={faqsActions}
        />
      ) : (
        error && (
          <AlertModal
            icon={<FaTimes className="text-red-500 text-4xl" />}
            status={"Danger"}
            message={message}
            button={"Okay"}
            actions={faqsActions}
          />
        )
      )}
      {showModal && (
        <FormModal
          addTitle={"Add Faq"}
          updateTitle={"Update Faq"}
          fields={faqsFields}
          actions={faqsActions}
          form={form}
          edit={edit}
        />
      )}

      <div className=" md:pr-10 md:pl-5 md:w-9/12">
        <div className="flex justify-between">
          <div className="flex items-center font-bold cursor-pointer text-xl mb-3">
            <p>FAQS Management</p>
          </div>
          <button
            onClick={e => addFaq(e)}
            className="py-2 px-4 bg-indigo-500 text-white rounded"
          >
            Add Faq
          </button>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {header.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 relative ">
                    {allFaqs?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.question}
                        </td>
                        <td className=" py-4 max-w-[30%] text-sm text-gray-800 whitespace-nowrap">
                          <textarea
                            name=""
                            disabled
                            id=""
                            className="w-full p-2 resize-none"
                            cols="40"
                            rows="10"
                          >
                            {item.answer}
                          </textarea>
                        </td>

                        <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                          <button
                            onClick={e => editFaq(e, item.id)}
                            className="text-green-500 hover:text-red-700 mr-3"
                            href="#"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete(item.id)}
                            className="text-red-500 hover:text-red-700"
                            href="#"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
