import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import React from "react";
import { Pie } from "react-chartjs-2";
import { STATISTICS } from "../store/dashboard-slice";
import formatCurrency from "../components/formatCurrency";
import { FaPesoSign } from "react-icons/fa6";
const barChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [
      {
        barPercentage: 1,
        gridLines: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          fontColor: "#7e8591",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          fontColor: "#7e8591",
        },
      },
    ],
  },
  legend: {
    labels: {
      fontColor: "#7e8591",
      fontSize: 16,
    },
  },
};
const startYear = 2023;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const Dashboard = () => {
  const [year, setYear] = useState(new Date().getFullYear()),
    [month, setMonth] = useState(new Date().getMonth() + 1),
    [models, setModels] = useState([]),
    [labels, setLabels] = useState([]),
    { items, annual_sold, annual_sales, monthly_sales, monthly_sold, daily } =
      useSelector(state => state.dashboard),
    dispatch = useDispatch();

  useEffect(() => {
    setModels([]);
    dispatch(STATISTICS({ year, month }));
  }, [year, month]);

  useEffect(() => {
    let datas = [];
    let lable = [];
    var o = Math.round,
      r = Math.random,
      s = 255;
    items.map(item => {
      lable.push(item.type);
      datas.push(item.total);

      // lable = [months[item.month - 1]];
      // const data = {
      //   label: `${months[item.month - 1]} SOLD(${item.total})`,
      //   data: [item.total_sales],
      //   borderWidth: 1,
      //   backgroundColor: [
      //     "rgba(" +
      //       o(r() * s) +
      //       "," +
      //       o(r() * s) +
      //       "," +
      //       o(r() * s) +
      //       "," +
      //       r().toFixed(1) +
      //       ")",
      //   ],
      // };
      // datas.push(data);
    });
    setLabels(lable);
    let model = [
      {
        label: "# of Votes",
        data: datas,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ];
    setModels(model);
    console.log(model);
  }, [items]);

  const handleYear = () => {
    let from = startYear;
    let years = [];
    while (from <= new Date().getFullYear()) {
      years.push(from);
      from++;
    }
    return years.map((year, i) => {
      return (
        <option key={`year-${i}`} value={Number(year)}>
          {year}
        </option>
      );
    });
  };
  const options = {
    tooltips: {
      callbacks: {
        indexY: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce((acc, value) => acc + value, 0);
          const currentValue = dataset.data[tooltipItem.index];
          const percentage = Math.floor((currentValue / total) * 100 + 0.5);
          return `${data.labels[tooltipItem.index]}: ${percentage}%`;
        },
      },
    },
  };
  return (
    <>
      <div className="md:w-4/5 md:pr-10 md:pl-5">
        <p className="font-bold text-xl mb-3">Dashboard</p>
        <h1>Sales Summary</h1>
        <div className="flex items-center text-gray-800">
          <div className="p-4 w-full">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 ">
                <div className="flex flex-row bg-green-200 shadow-sm rounded p-4">
                  <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-300 text-white-500">
                    <FaPesoSign />
                    {/* <i class="fa-solid fa-peso-sign"></i> */}
                  </div>
                  <div className="flex flex-col flex-grow ml-4">
                    <div className="text-sm text-gray-500">Daily Sales</div>
                    <div className="font-bold text-lg">
                      {formatCurrency(daily)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 ">
                <div className="flex flex-row bg-blue-200 shadow-sm rounded p-4">
                  <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-400 text-white-500">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex flex-col flex-grow ml-4">
                    <div className="text-sm text-gray-500">Sold Monthly</div>
                    <div className="font-bold text-lg">
                      {new Intl.NumberFormat("en-US").format(monthly_sold)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 ">
                <div className="flex flex-row bg-green-200 shadow-sm rounded p-4">
                  <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-300 text-blue-500">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex flex-col flex-grow ml-4">
                    <div className="text-sm text-gray-500">
                      Total Sales Monthly
                    </div>
                    <div className="font-bold text-lg">
                      {formatCurrency(monthly_sales)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 ">
                <div className="flex flex-row bg-blue-200 shadow-sm rounded p-4">
                  <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-blue-400 text-white-500">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex flex-col flex-grow ml-4">
                    <div className="text-sm text-gray-500">Sold Annualy</div>
                    <div className="font-bold text-lg">
                      {new Intl.NumberFormat("en-US").format(annual_sold)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 ">
                <div className="flex flex-row bg-green-200 shadow-sm rounded p-4">
                  <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl bg-green-300 text-blue-500">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex flex-col flex-grow ml-4">
                    <div className="text-sm text-gray-500">
                      Total Sales Annualy
                    </div>
                    <div className="font-bold text-lg">
                      {formatCurrency(annual_sales)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <select
          required
          value={year}
          onChange={e => setYear(e.target.value)}
          className="text-gray-500 border py-3 pr-3  rounded-md shadow-md w-25 focus:outline-none"
        >
          <option value="Select Status">Select Year</option>
          {handleYear()}
        </select>
        <select
          required
          value={month}
          onChange={e => setMonth(e.target.value)}
          className="ml-2 text-gray-500 border py-3 pr-3  rounded-md shadow-md w-25 focus:outline-none"
        >
          <option value="Select Status">Select Month</option>
          {months.map((month, i) => {
            return (
              <option key={`month-${i}`} value={i + 1}>
                {month}
              </option>
            );
          })}
        </select>
        <ul className="my-10">
          {models.length > 0 &&
            models[0].data.length > 0 &&
            models[0].data.map((model, index) => {
              console.log(models[0].data.length, "length");
              console.log(index);
              return (
                <li key={`model-${index}`}>
                  {labels[index]}: {items[index].total_sales}
                </li>
              );
            })}
        </ul>
        <Pie
          data={{
            labels,
            datasets: models,
          }}
          // options={{ legend: "asf" }}
        />
        {/* <Bar
          options={barChartOptions}
          data={{
            labels,
            datasets: models,
          }}
          height={150}
        /> */}
      </div>
    </>
  );
};

export default Dashboard;
