import React, { useState } from "react";
import { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../App";
import Modal from "../components/AlertModal";
import {
  addToCart,
  getUserCart,
  orderActions,
  updateOrderIfExist,
} from "../store/order-slice";
import { getProduct } from "../store/product-slice";
import Swal from "sweetalert2";

const Product = () => {
  let token = useAuth();
  const { product } = useSelector(state => state.product);
  const { form, success, usersCart, error } = useSelector(state => state.order);
  const [indexPrice, setIndexPrice] = useState(0);
  const navigate = useNavigate();
  const { product_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProduct(product_id));
    const value = product_id;
    const name = "product_id";
    dispatch(orderActions.setForm({ name, value }));
    dispatch(getUserCart("Paid"));
    dispatch(orderActions.setPrice(indexPrice));
  }, [product_id, dispatch, indexPrice]);

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(orderActions.setForm({ name, value }));
  };

  const addCart = async e => {
    e.preventDefault();
    try {
      if (!token) navigate("/login");
      else {
        if (localStorage.getItem("toc") === "agree") {
          const isExist = await usersCart.find(
            item =>
              item.product_id === parseInt(product_id) &&
              item.unit_price ===
                parseInt(JSON.parse(product.sizes)[indexPrice].price)
          );
          if (!isExist || isExist.status !== "onCart")
            dispatch(addToCart(form));
          else dispatch(updateOrderIfExist(form));

          dispatch(orderActions.setSuccess(true));
          setTimeout(() => {
            dispatch(orderActions.resetForm());
          }, 1000);

          setTimeout(() => {
            dispatch(orderActions.setSuccess(false));
            navigate(`/`);
          }, 1000);
        } else {
          Swal.fire({
            title: "Are you sure?",
            html: `<p>Our Terms and Conditions for cake orders include the following:</p>
                  <br/>
                  
                <ol className="list-decimal">
                  <li>
                    Orders must be placed through our website, fifty percent
                    (50%) payment required at the time of purchase.
                    Customization requests should be clearly communicated
                  </li>
                  <li>
                    Delivery is available within cavite city with the minimum of
                    500 pesos worth of product and free delivery for customize
                    cake, and accurate delivery information must be provided.
                  </li>
                  <li>
                    If the purchase are below 500 the customer must cater the
                    delivery fee.
                  </li>
                  <li>
                    Once placed, orders cannot be canceled or modified, and we
                    do not offer refunds for canceled orders.
                  </li>
                  <li>
                    We assure the quality of our cakes but do not provide
                    refunds due to our no-refund policy.
                  </li>
                </ol>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, I agree!",
          }).then(async result => {
            if (result.isConfirmed) {
              localStorage.setItem("toc", "agree");
              const isExist = await usersCart.find(
                item =>
                  item.product_id === parseInt(product_id) &&
                  item.unit_price ===
                    parseInt(JSON.parse(product.sizes)[indexPrice].price)
              );
              if (!isExist || isExist.status !== "onCart")
                dispatch(addToCart(form));
              else dispatch(updateOrderIfExist(form));

              dispatch(orderActions.setSuccess(true));
              setTimeout(() => {
                dispatch(orderActions.resetForm());
              }, 1000);

              setTimeout(() => {
                dispatch(orderActions.setSuccess(false));
                navigate(`/`);
              }, 1000);
            }
          });
        }
      }
    } catch (e) {
      console.log("Failed!");
    }
  };

  const handleSetPrice = i => {
    setIndexPrice(i);
    dispatch(orderActions.setPrice(indexPrice));
  };

  return (
    <>
      {success ? (
        <Modal
          icon={<FaCheck className="text-green-500 text-4xl" />}
          status={"Success"}
          message={"Added Cart Successful"}
        />
      ) : (
        error && (
          <Modal
            icon={<FaCheck className="text-green-500 text-4xl" />}
            status={"Success"}
            message={"Added Cart Successful"}
          />
        )
      )}

      <div className="md:h-screen flex items-center justify-center">
        <div className="md:flex items-center gap-10 my-12 md:my-0 md:mx-24  w-full justify-between ">
          <img
            src={`${process.env.REACT_APP_API_URL}/storage/${product.image}`}
            alt="cake"
            className="mb-3 md:mb-0 h-100 w-[500px]"
          />
          <div className="self-start w-full">
            <p className="font-bold text-2xl text-indigo-500">{product.name}</p>

            <p className="text-gray-500 my-3">
              <span className="font-bold">Description:</span>{" "}
              {product.description}
            </p>

            <p className="text-gray-500">
              {product.sizes &&
                JSON.parse(product.sizes).length > 0 &&
                JSON.parse(product.sizes).map((data, index) => (
                  <>
                    <button
                      onClick={() => handleSetPrice(index)}
                      class={`bg-blue-500 m-2 ${
                        indexPrice === index && "bg-green-700"
                      } text-white font-bold py-2 px-4 border border-blue-700 rounded`}
                    >
                      {data.size}: {data.price}
                    </button>
                    <br />
                  </>
                ))}
              <span className="font-bold">Price:</span> ₱
              {product.sizes && JSON.parse(product.sizes)[indexPrice].price}
            </p>
            <form onSubmit={addCart}>
              {product.type === "Cakes" && (
                <div className="my-3">
                  <input
                    required
                    type="text"
                    placeholder="What Message Would You Like On The Cake?"
                    name="message"
                    value={form.message}
                    onChange={e => handleChange(e)}
                    className="py-3 px-5 border w-full placeholder-black"
                  />
                </div>
              )}

              <div className="mt-3 flex justify-between">
                <input
                  required
                  type="number"
                  placeholder="Quantity"
                  name="quantity"
                  value={form.quantity}
                  className="py-3 px-5 border w-9/12 placeholder-black"
                  onChange={e => handleChange(e)}
                />

                <button
                  // onClick={e => addCart(e)}
                  className="py-3 px-5 bg-indigo-500 text-white w-2/12 "
                >
                  Add to Cart
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
