import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Navigate } from "react-router-dom";
import { useAuth } from "../App";

const SideBarLayout = () => {
  let isAuth = useAuth();
  const handleNotAdmin = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
  };
  return isAuth?.role === "Admin" ? (
    <div className="md:flex justify-between min-h-screen my-12 ">
      <Sidebar />
      <Outlet />
    </div>
  ) : (
    <>
      {handleNotAdmin()}
      <Navigate to="/login" />
    </>
  );
};

export default SideBarLayout;
