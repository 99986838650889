import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { FAQS } from "../store/dashboard-slice";

export default function Faqs() {
  const [faqs, setFaqs] = useState([]),
    { collection } = useSelector(state => state.dashboard),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(FAQS());
  }, [dispatch]);

  useEffect(() => {
    setFaqs(collection);
  }, [collection]);

  return (
    <section class="bg-white ">
      <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 ">
          Frequently asked questions
        </h2>
        <div class="grid pt-8 text-left border-t border-gray-200 grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
          {faqs.map(item => (
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="flex flex-row font-bold text-xl mb-2">
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-gray-500 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  {item.question}
                </div>
                <p class="text-gray-700  break-words px-2">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
