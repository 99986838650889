import { FaAudioDescription, FaHeading } from "react-icons/fa";

export const reviewsFields = [
  {
    ph: "Title",
    icon: <FaHeading />,
    field: "title",
    type: "text",
  },
  {
    ph: "Description",
    type: "textarea",
    icon: <FaAudioDescription />,
    field: "description",
  },
];
