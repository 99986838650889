import React from "react";
import bg from "../assets/img/banner.jpg";
import aboutImg from "../assets/img/aboutUs.png";

const About = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto">
          <div className="h-screen flex items-center justify-center">
            <div className="md:flex items-center justify-center gap-3">
              <div className="flex justify-center mb-3 md:mb-0 md:basis-3/6">
                <img src={aboutImg} alt="aboutUs" className="w-3/4" />
              </div>
              <div className="bg-white rounded p-12 self-start md:basis-3/6 opacity-90">
                <h1 className="font-thin text-3xl text-violet-500 mb-5">
                  About Us
                </h1>
                <p className="mb-3 font-bold">
                  Special homemade baked goodies. Customize your own cake with
                  us!
                </p>
                <p className="leading-8">
                  For nearly seven years, The Purple Box flourished as an online
                  entity until the decision to establish a physical store. Now,
                  nearly two years into its brick-and-mortar presence, it
                  continues to carve out a distinct place in the realm of cakes
                  and pastries, steadily enhancing its reputation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
