import { FaCheckCircle, FaQuestion } from "react-icons/fa";

export const faqsFields = [
  {
    ph: "Quetion",
    icon: <FaQuestion />,
    field: "question",
    type: "text",
  },
  {
    ph: "Answer",
    type: "textarea",
    icon: <FaCheckCircle />,
    field: "answer",
  },
];
