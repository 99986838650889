import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import TextBox from "../../components/TextBox";
import { useDispatch, useSelector } from "react-redux";
import { SAVE, UPDATE } from "../../store/galleries-slice";
import { FaCheckCircle, FaDollarSign } from "react-icons/fa";

const Modal = ({ addTitle, updateTitle, actions, form, edit }) => {
  const dispatch = useDispatch();
  const { errors, loading } = useSelector(state => state.user);
  const [img, setImg] = useState(undefined);

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(actions.setForm({ name, value }));
  };

  const closeModal = () => {
    dispatch(actions.setShowModal(false));
    dispatch(actions.setEdit(false));
    dispatch(actions.resetForm());
    dispatch(actions?.resetErrors());
  };

  const formData = new FormData();

  const save = async e => {
    e.preventDefault();
    try {
      Object.keys(form).map(item => {
        formData.append(item, form[item]);
      });
      formData.append("enctype", "multipart/form-data");
      dispatch(SAVE(formData));

      dispatch(actions.resetErrors());
      dispatch(actions.resetForm());
    } catch (e) {
      console.log(e);
    }
  };

  const update = (e, id) => {
    e.preventDefault();
    try {
      Object.keys(form).map(item => {
        formData.append(item, form[item]);
      });

      formData.append("id", id);
      formData.append("enctype", "multipart/form-data");
      // formData.append("img", img);
      dispatch(UPDATE(formData));

      dispatch(actions.resetForm());
    } catch (e) {
      console.log(e);
    }
  };

  const getFile = async e => {
    dispatch(actions.setForm({ name: "img", value: e.target.files[0] }));
    setImg(e.target.files[0]);
  };
  return (
    <div className="bg-zinc-200 opacity-90 fixed inset-0 z-50">
      <div className="flex justify-center items-center h-screen">
        <motion.div
          initial={{ y: "-1000px" }}
          animate={{ y: "-50px" }}
          transition={{ type: "spring", duration: 0.5 }}
          className="bg-white rounded-xl py-6 px-12 flex flex-col justify-center w-4/12"
        >
          <p className="font-bold text-2xl text-gray-900 mb-3">
            {edit ? updateTitle : addTitle}
          </p>

          <div>
            <form
              onSubmit={e => (!loading && edit ? update(e, form.id) : save(e))}
            >
              <div className="mb-2">
                <TextBox
                  required
                  type={"text"}
                  placeholder={"Enter Name"}
                  icon={<FaCheckCircle />}
                  field={"name"}
                  value={form.name}
                  handleChange={handleChange}
                  errorMsg={errors.name ? errors.name : ""}
                />
              </div>
              <div className="mb-2">
                <textarea
                  id="description"
                  rows="4"
                  value={form.description}
                  name="description"
                  onChange={handleChange}
                  errorMsg={errors.description ? errors.description : ""}
                  class="block mt-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your thoughts here..."
                ></textarea>
              </div>
              <div className="mb-2">
                <TextBox
                  required
                  type={"number"}
                  placeholder={"Enter Price"}
                  icon={<FaDollarSign />}
                  field={"price"}
                  value={form.price}
                  handleChange={handleChange}
                  errorMsg={errors.price ? errors.price : ""}
                />
              </div>
              <div className="mb-2">
                <select
                  required
                  name="type"
                  id="type"
                  value={form.type}
                  onChange={handleChange}
                  className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                >
                  <option value="All" disabled selected></option>
                  <option value="Cakes">Cakes</option>
                  <option value="Beverages">Beverages</option>
                  <option value="Pastries">Pastries</option>
                </select>
              </div>
              <div className="mb-2">
                <input
                  required={!edit}
                  type="file"
                  placeholder="Upload Image"
                  name="image"
                  onChange={e => getFile(e)}
                  accept="image/png, image/gif, image/jpeg"
                  className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                />
              </div>
              <div className="text-right mt-3">
                <button
                  onClick={closeModal}
                  className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                >
                  Close
                </button>
                {!edit ? (
                  <button
                    className="bg-indigo-500 text-white py-2 px-4 rounded"
                    // onClick={save}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    // onClick={update}
                    className="bg-indigo-500 text-white py-2 px-4 rounded"
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Modal;
