import {
  FaAudioDescription,
  FaImage,
  FaUser,
  FaVrCardboard,
} from "react-icons/fa";

export const galleriesFields = [
  {
    ph: "Name",
    icon: <FaUser />,
    field: "name",
  },
  {
    ph: "Type",
    icon: <FaVrCardboard />,
    field: "type",
    dropdown: ["Cakes", "Beverages", "Pastries"],
  },
  {
    ph: "Description",
    icon: <FaAudioDescription />,
    field: "description",
  },
  {
    ph: "Image",
    icon: <FaImage />,
    field: "image",
    file: true,
  },
];
