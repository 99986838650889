import ListProducts from "../components/ListProducts";
import Showcase from "../components/Showcase";
import BestSeller from "../components/BestSeller";
import Testimonies from "./Testimonies";

const Index = () => {
  return (
    <>
      <Showcase />
      <Testimonies />
      <BestSeller />
      <ListProducts />
    </>
  );
};

export default Index;
