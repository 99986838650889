import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const BROWSE = createAsyncThunk("faqs/", async (payload, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/faqs?type=${payload}`
    );
    return resp.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const SAVE = createAsyncThunk("faqs/save", async (payload, thunkAPI) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/faqs/save`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return resp.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const DELETE = createAsyncThunk("faqs/delete", async (id, thunkAPI) => {
  try {
    const resp = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/faqs/${id}/destroy`
    );
    thunkAPI.dispatch(BROWSE("All"));
    return resp.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const UPDATE = createAsyncThunk(
  "faqs/UPDATE",
  async (payload, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/faqs/${payload.id}/update`,
        payload,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // dispatch(getAllOrders(getState().order.status) || "onCart");
      // dispatch(getQtyEachOrder());
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const FIND = createAsyncThunk("faqs/FIND", async (id, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/faqs/${id}`
    );
    return resp.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

const faqsSlice = createSlice({
  name: "faqs",
  initialState: {
    allFaqs: [],
    faqs: {},
    form: {
      question: "",
      answer: "",
    },
    error: false,
    showModal: false,
    edit: false,
    success: false,
    errors: {},
    message: "",
  },
  reducers: {
    resetErrors(state) {
      state.errors = {};
    },
    fillForm(state, action) {
      Object.keys(action.payload).forEach(item => {
        state.form[item] = action.payload[item];
      });
    },
    setForm(state, action) {
      state.form[action.payload.name] = action.payload.value;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setEdit(state, action) {
      state.edit = action.payload;
    },
    getAll(state, action) {
      state.allUsers = action.payload;
    },
    reset(state) {
      state.allUsers = [];
    },
    resetForm(state) {
      state.form = {
        question: "",
        answer: "",
      };
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setType(state, action) {
      state.form["type"] = action.payload;
    },
    setIsBestSeller(state, action) {
      state.form["is_best_seller"] = action.payload;
    },
    setErr(state, action) {
      state.error = action.payload;
    },
    setFailed(state, action) {
      state.failed = action.payload;
    },
  },
  extraReducers: {
    [BROWSE.pending]: state => {
      console.log("loading");
    },
    [BROWSE.fulfilled]: (state, action) => {
      state.allFaqs = action.payload;
    },
    [BROWSE.rejected]: state => {
      console.log("rejected");
    },

    [SAVE.pending]: state => {
      console.log("loading");
    },
    [SAVE.fulfilled]: (state, action) => {
      state.message = "Item saved successfully";
      state.showModal = false;
      state.success = true;
      state.error = false;
    },
    [SAVE.rejected]: state => {
      state.message = "Item saved Error";
      state.error = true;
      state.showModal = false;
      state.success = false;
      console.log("rejected");
    },

    [DELETE.pending]: state => {
      console.log("loading");
    },
    [DELETE.fulfilled]: (state, action) => {
      state.message = "Item deleted successfully";
      state.success = true;
      console.log("fulfilled");
    },
    [DELETE.rejected]: state => {
      state.message = "Faild to deleted";
      state.error = true;
      state.success = false;
      console.log("rejected");
    },

    [UPDATE.pending]: state => {
      console.log("loading");
    },
    [UPDATE.fulfilled]: (state, action) => {
      state.message = "Item Updete successful";
      state.success = true;
      state.edit = false;
      state.showModal = false;
      state.showModal = false;
      state.error = false;
    },
    [UPDATE.rejected]: state => {
      state.message = "Item Updete Error";
      state.error = true;
      state.showModal = false;
      state.success = false;
      console.log("rejected");
    },

    [FIND.pending]: state => {
      console.log("loading");
    },
    [FIND.fulfilled]: (state, action) => {
      state.faqs = action.payload;
    },
    [FIND.rejected]: state => {
      console.log("rejected");
    },
  },
});

export const faqsActions = faqsSlice.actions;

export default faqsSlice;
