import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BROWSE, LIST, SAVE, reviewsActions } from "../store/reviews-slice";
import { useAuth, useToken } from "../App";
import AlertModal from "../components/AlertModal";
import { FaCheck, FaCheckCircle, FaTimes } from "react-icons/fa";

export default function ReviewsUser() {
  const [reviews, setReviews] = useState([]),
    { allReviews, form, success, error, message, count } = useSelector(
      state => state.reviews
    ),
    token = useToken(),
    user = useAuth(),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(BROWSE(token));
    dispatch(LIST(user.id));
  }, [dispatch, success, token]);

  useEffect(() => {
    setReviews(allReviews);
  }, [allReviews]);

  const handleChange = (name, value) => {
    dispatch(reviewsActions.setForm({ name, value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(SAVE(form));
    // dispatch(reviewsActions.resetForm());
  };
  return (
    <>
      {success ? (
        <AlertModal
          icon={<FaCheckCircle className="text-green-500 text-4xl" />}
          status={"Success"}
          message={message}
          button={"OK"}
          actions={reviewsActions}
        />
      ) : (
        error && (
          <AlertModal
            icon={<FaTimes className="text-green-500 text-4xl" />}
            status={"Warning"}
            message={message}
            button={"OK"}
            actions={reviewsActions}
          />
        )
      )}
      <div className="container my-24 mx-auto md:px-6">
        <section className="mb-32 text-center">
          <h2 className="mb-12 text-3xl font-bold">Reviews</h2>

          <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
            {reviews.map(review => (
              <div class="max-w-sm rounded overflow-hidden shadow-lg">
                <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2">{review?.title}</div>
                  <p class="text-gray-700 text-base">{review?.description}</p>
                </div>
                <div class="px-6 pt-4 pb-2">
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    {review?.full_name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>
        {token && count < 3 && (
          <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
            <div className="mb-5">
              <label
                for="base-input"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Title
              </label>
              <input
                required
                value={form?.title}
                type="text"
                id="base-input"
                onChange={e => handleChange("title", e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-5">
              <label
                for="large-input"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Description
              </label>
              <textarea
                required
                onChange={e => handleChange("description", e.target.value)}
                value={form?.description}
                type="text"
                id="large-input"
                className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <button
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Add Yours
            </button>
          </form>
        )}
      </div>
    </>
  );
}
