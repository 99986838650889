import React, { useState } from "react";
import { FaEnvelope, FaCheck, FaBox, FaLock } from "react-icons/fa";
import TextBox from "../../components/TextBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Modal from "../../components/AlertModal";
import ErrorMessage from "../../components/ErrorMessage";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [message, setMessage] = useState("");
  const { token, em } = useParams();
  const navigate = useNavigate();

  const login = async e => {
    e.preventDefault();
    if (token) {
      if (password !== cPassword) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password and Confirm Password must be same!",
        });
        return;
      }
      if (password.length < 8) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password must be 8 letter!",
        });
        return;
      }
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/update_password`,
          {
            email: em,
            password,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then(async res => {
          await Swal.fire({
            title: "Updated!",
            text: "Please login!",
            icon: "success",
          });
          navigate("/login");
        })
        .catch(err => {
          setFailed(true);
          setMessage(err.response.data.message);
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/forgot-password`, {
          email,
        })
        .then(res => {
          Swal.fire({
            title: "Check Email!",
            text: "Email send!",
            icon: "success",
          });
        })
        .catch(err => {
          setFailed(true);
          setMessage(err.response.data.message);
        });
    }
  };

  return (
    <>
      {success && (
        <Modal
          icon={<FaCheck className="text-green-500 text-4xl" />}
          status={"Success"}
          message={"Login Successful"}
        />
      )}

      <div className="h-screen flex items-center justify-center">
        <div className="shadow-md pt-12 px-12 w-full md:w-1/2 lg:w-1/3">
          <div className="flex items-center font-bold cursor-pointer text-xl justify-center mb-6">
            <span className="mr-1 text-indigo-500">
              <FaBox />
            </span>
            <p>FORGOT PASSWORD</p>
          </div>
          {failed && <ErrorMessage message={message} />}
          <form action="" className="my-3">
            {token ? (
              <>
                <div className="mb-1">
                  <TextBox
                    placeholder={"Password"}
                    icon={<FaLock />}
                    type={"password"}
                    value={password}
                    handleChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-1">
                  <TextBox
                    placeholder={"Confirm Password "}
                    icon={<FaLock />}
                    type={"password"}
                    value={cPassword}
                    handleChange={e => setCPassword(e.target.value)}
                  />
                </div>
              </>
            ) : (
              <div className="mb-1">
                <TextBox
                  placeholder={"Email"}
                  icon={<FaEnvelope />}
                  field={"email"}
                  value={email}
                  handleChange={e => setEmail(e.target.value)}
                />
              </div>
            )}

            <div className="text-right">
              <button
                onClick={e => login(e)}
                className="bg-green-500 text-white px-8 py-2 rounded"
              >
                {token ? "Reset Password" : "Send Email"}
              </button>
            </div>

            <div className="text-center pb-3 py-6">
              <sub className="flex justify-center">
                <p className="mr-1">Dont have account yet? </p>
                <Link to={"/register"}>
                  <p className="text-blue-500 hover:underline">Sign Up</p>
                </Link>
              </sub>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
