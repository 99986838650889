import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import CustomCakeModal from "../components/CustomCakeModal";
import ViewProductModal from "../components/ViewProductModal";
import { fetchAllCustomCake } from "../store/custom-cake-slice";
import {
  deleteOnCart,
  getAllOrders,
  getOrder,
  getQtyEachOrder,
  orderActions,
} from "../store/order-slice";
import AlertModal from "../components/AlertModal";
import Swal from "sweetalert2";

const Order = () => {
  const headers = [
    "Customer Name",
    "Customer Number",
    "Product Name",
    "Quantity",
    "Reference Number",
    "Paid",
    "Remaining",
    "Total Price",
    "Delivery Date",
    "Delivery Address",
  ];
  // const selectRef = useRef([]);
  const buttonRef = useRef([]);

  const dispatch = useDispatch();

  // const [statsField, setStatsField] = useState("");
  const [st, setSt] = useState("");

  // const editSaveOrder = (e, id) => {
  //   e.preventDefault();
  //   selectRef.current[id].disabled = !selectRef.current[id].disabled;
  //   if (selectRef.current[id].disabled) {
  //     buttonRef.current[id].innerText = "Edit";
  //     dispatch(updateOrder({ id, statsField }));
  //   }
  //   if (!selectRef.current[id].disabled)
  //     buttonRef.current[id].innerText = "Save";
  // };

  // const handleSelect = e => {
  //   e.preventDefault();
  //   setStatsField(e.target.value);
  // };

  const { showModal, allOrders, status, qtyEachOrder, success, isLoading } =
    useSelector(state => state.order);
  const { customCakeModal } = useSelector(state => state.customCake);

  const header = status === "Completed" ? headers : [...headers, "Actions"];

  useEffect(() => {
    dispatch(getQtyEachOrder());
    dispatch(getAllOrders(status || "onCart"));
    dispatch(fetchAllCustomCake(status));
  }, [status, dispatch]);

  const navigate = (e, stats) => {
    setSt(stats);
    dispatch(orderActions.setStatus(stats));
    dispatch(getAllOrders(stats));
    dispatch(fetchAllCustomCake(stats));
  };

  const viewOrder = (e, id) => {
    e.preventDefault();
    dispatch(getOrder({ id, status: st || "onCart" }));
    dispatch(orderActions.setShowModal(true));
  };

  // const viewCustomOrder = (e, id) => {
  //   e.preventDefault();
  //   dispatch(getCustomCake(id));
  //   dispatch(customCakeActions.setCustomCakeModal(true));
  // };

  const cakeCartDelete = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteOnCart(id));
        dispatch(getOrder({ id, status: st || "onCart" }));
      }
    });
  };
  return (
    <>
      {success && (
        <AlertModal
          icon={<FaCheck className="text-green-500 text-4xl" />}
          status={"Success"}
          message={"Item has been updated."}
          button={"Okay"}
          actions={orderActions}
        />
      )}

      {showModal && (
        <ViewProductModal
          icon={<FaCheck className="text-green-500 text-4xl" />}
          status={status}
          message={
            "Item added to cart. The seller will update the price for this customize cake."
          }
          button={"Okay"}
          actions={orderActions}
        />
      )}

      {customCakeModal && <CustomCakeModal />}

      <div className=" md:pr-10 md:pl-5 md:w-9/12">
        <div className="flex justify-between">
          <div className="flex items-center font-bold cursor-pointer text-xl mb-3">
            <p>Order Management</p>
          </div>
        </div>

        <ul className="flex justify-between items-center px-2 text-center">
          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "onCart" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "onCart")}
          >
            onCart (no-price) ({qtyEachOrder?.oncart})
          </li>
          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Paid" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Paid")}
          >
            Paid ({qtyEachOrder?.topay})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Processing" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Processing")}
          >
            Processing ({qtyEachOrder?.processing})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Ready-For-Delivery" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Ready-For-Delivery")}
          >
            Delivery ({qtyEachOrder?.delivery})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Completed" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Completed")}
          >
            Completed ({qtyEachOrder?.completed})
          </li>
        </ul>

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-scroll border rounded-lg ">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {header.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 relative">
                    {allOrders?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.user?.first_name} {item?.user?.last_name}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.user?.contact_num}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.type === "normal"
                            ? item?.product?.name
                            : item.type}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.quantity}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.ref_no}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.paid}
                        </td>
                        <td
                          className={`${
                            item?.remaining > 0
                              ? "text-red-400"
                              : "text-green-400"
                          } px-6 py-4 text-sm  whitespace-nowrap`}
                        >
                          {item?.remaining}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.total_price}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.delivery_date}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item?.delivery_address}
                        </td>

                        {status !== "Completed" && (
                          <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                            {isLoading ? (
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <>
                                <button
                                  onClick={e => viewOrder(e, item.id)}
                                  className="text-green-500 hover:text-red-700 mr-3"
                                  href="#"
                                  id={item?.id}
                                  ref={el => (buttonRef.current[item.id] = el)}
                                >
                                  View
                                </button>
                                <button
                                  onClick={e => cakeCartDelete(e, item.id)}
                                  className="text-red-500 hover:text-red-700"
                                  href="#"
                                >
                                  Delete
                                </button>
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
