import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

export default function EmailVerify() {
  const { email, token } = useParams(),
    [message, setMessage] = useState(""),
    navigate = useNavigate();

  useEffect(() => {
    handleVerify();
  });
  const handleVerify = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/email_verify/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        localStorage.setItem("token", token);
        const authUser = JSON.stringify(res.data.user);
        localStorage.setItem("authUser", authUser);
        setMessage("Verify successful");
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        if (res.data.user.role === "Admin") navigate("/orders");
        else navigate("/");
        window.location.reload();
      })
      .catch(err => {
        setMessage("Verify Failed");
      });
  };
  return (
    <div className="min-h-[100vh] flex flex-grid items-center justify-center">
      <div className="bg-blue-200 p-4 rounded">
        <p className="text-xl">{message}</p>
      </div>
    </div>
  );
}
