import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const BROWSE = createAsyncThunk(
  "galleries/",
  async (payload, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/galleries?type=${payload}`
      );
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const SAVE = createAsyncThunk(
  "galleries/save",
  async (payload, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/galleries/save`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const DELETE = createAsyncThunk(
  "galleries/delete",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/galleries/${id}/destroy`
      );
      thunkAPI.dispatch(BROWSE("All"));
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const UPDATE = createAsyncThunk(
  "galleries/UPDATE",
  async (payload, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/galleries/${payload.get(
          "id"
        )}/update`,
        payload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // dispatch(getAllOrders(getState().order.status) || "onCart");
      // dispatch(getQtyEachOrder());
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const FIND = createAsyncThunk("galleries/FIND", async (id, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/galleries/${id}`
    );
    return resp.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

const galleriesSlice = createSlice({
  name: "galleries",
  initialState: {
    allGalleries: [],
    galleries: {},
    form: {
      name: "",
      price: 0,
      type: "Cakes",
      description: "",
    },
    error: false,
    showModal: false,
    edit: false,
    success: false,
    errors: {},
    message: "",
  },
  reducers: {
    resetErrors(state) {
      state.errors = {};
    },
    fillForm(state, action) {
      Object.keys(action.payload).forEach(item => {
        state.form[item] = action.payload[item];
      });
    },
    setForm(state, action) {
      state.form[action.payload.name] = action.payload.value;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setEdit(state, action) {
      state.edit = action.payload;
    },
    getAll(state, action) {
      state.allUsers = action.payload;
    },
    reset(state) {
      state.allUsers = [];
    },
    resetForm(state) {
      state.form = {
        name: "",
        price: 0,
        type: "Cakes",
        description: "",
      };
    },
    setType(state, action) {
      state.form["type"] = action.payload;
    },
    setIsBestSeller(state, action) {
      state.form["is_best_seller"] = action.payload;
    },

    setSuccess(state, action) {
      state.success = action.payload;
    },
    setErr(state, action) {
      state.error = action.payload;
    },
    setFailed(state, action) {
      state.failed = action.payload;
    },
  },
  extraReducers: {
    [BROWSE.pending]: state => {
      console.log("loading");
    },
    [BROWSE.fulfilled]: (state, action) => {
      state.allGalleries = action.payload.data;
    },
    [BROWSE.rejected]: state => {
      console.log("rejected");
    },

    [SAVE.pending]: state => {
      console.log("loading");
    },
    [SAVE.fulfilled]: (state, action) => {
      state.form = {
        name: "",
        price: 0,
        type: "Cakes",
        description: "",
      };
      state.message = "Item saved successfully";
      state.showModal = false;
      state.success = true;
      state.error = false;
    },
    [SAVE.rejected]: state => {
      state.message = "Item saved Error";
      state.error = true;
      state.success = false;
      state.showModal = false;
      console.log("rejected");
    },

    [DELETE.pending]: state => {
      console.log("loading");
    },
    [DELETE.fulfilled]: (state, action) => {
      state.message = "Item deleted successfully";
      state.success = true;
      console.log("fulfilled");
    },
    [DELETE.rejected]: state => {
      state.message = "Faild to deleted";
      state.error = true;
      state.success = false;
      console.log("rejected");
    },

    [UPDATE.pending]: state => {
      console.log("loading");
    },
    [UPDATE.fulfilled]: (state, action) => {
      state.message = "Item Updete successful";
      state.success = true;
      state.edit = false;
      state.showModal = false;
      state.error = false;
    },
    [UPDATE.rejected]: state => {
      state.message = "Item Updete Error";
      state.error = true;
      state.showModal = false;
      state.success = false;
      console.log("rejected");
    },

    [FIND.pending]: state => {
      console.log("loading");
    },
    [FIND.fulfilled]: (state, action) => {
      state.galleries = action.payload;
    },
    [FIND.rejected]: state => {
      console.log("rejected");
    },
  },
});

export const galleriesActions = galleriesSlice.actions;

export default galleriesSlice;
