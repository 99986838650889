import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BROWSE } from "../store/reviews-slice";
import { Link } from "react-router-dom";

export default function Testimonies() {
  const [reviews, setReviews] = useState([]),
    { allReviews } = useSelector(state => state.reviews),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(BROWSE(""));
  }, [dispatch]);

  useEffect(() => {
    setReviews(allReviews.slice(0, 3));
  }, [allReviews]);
  return (
    <section className="mb-32 text-center ">
      <h2 className="mb-12 text-3xl font-bold">Reviews</h2>

      <div className="flex align-center justify-around">
        {reviews.map(review => (
          <div class="max-w-sm rounded overflow-hidden shadow-lg border-solid border-2 border-[#AC8FBD]">
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">{review?.title}</div>
              <p class="text-gray-700 text-base">{review?.description}</p>
            </div>
            <div class="px-6 pt-4 pb-2">
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {review?.full_name}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="flex align-center justify-center w-[100vw] mt-4 ">
        <Link to="/reviews">
          <button className="rounded bg-[#AE8EBF] p-2 ">Readmore</button>
        </Link>
      </div>
    </section>
  );
}
