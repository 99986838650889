import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "./modal";
import { galleriesFields } from "../../helper/GalleriesField";
import { DELETE, BROWSE, galleriesActions } from "../../store/galleries-slice";
import AlertModal from "../../components/AlertModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import none from "../../assets/img/none.jpg";
import Swal from "sweetalert2";

export default function Galleries() {
  const header = ["Image", "Name", "Description", "Price", "Action"];

  const { showModal, allGalleries, success, error, message } = useSelector(
    state => state.galleries
  );
  const dispatch = useDispatch();

  const form = useSelector(state => state.galleries.form);
  const edit = useSelector(state => state.galleries.edit);

  useEffect(() => {
    dispatch(BROWSE("All"));
  }, [dispatch, success]);

  const editGallery = (e, id) => {
    e.preventDefault();
    dispatch(galleriesActions.setEdit(true));
    dispatch(galleriesActions.setShowModal(true));

    const data = allGalleries.find(item => item.id === id);

    dispatch(galleriesActions.fillForm(data));
  };

  const addGallery = e => {
    e.preventDefault();
    dispatch(galleriesActions.setShowModal(true));
  };

  const handleDelete = id => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(DELETE(id));
      }
    });
  };

  return (
    <>
      {success ? (
        <AlertModal
          icon={<FaCheck className="text-green-500 text-4xl" />}
          status={"Success"}
          message={message}
          button={"Okay"}
          actions={galleriesActions}
        />
      ) : (
        error && (
          <AlertModal
            icon={<FaTimes className="text-red-500 text-4xl" />}
            status={"Danger"}
            message={message}
            button={"Okay"}
            actions={galleriesActions}
          />
        )
      )}
      {showModal && (
        <FormModal
          addTitle={"Add Gallery"}
          updateTitle={"Update Gallery"}
          fields={galleriesFields}
          actions={galleriesActions}
          form={form}
          edit={edit}
        />
      )}

      <div className=" md:pr-10 md:pl-5 md:w-9/12">
        <div className="flex justify-between">
          <div className="flex items-center font-bold cursor-pointer text-xl mb-3">
            <p>Gallery Management</p>
          </div>
          <button
            onClick={e => addGallery(e)}
            className="py-2 px-4 bg-indigo-500 text-white rounded"
          >
            Add Gallery
          </button>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {header.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 relative">
                    {allGalleries?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.image ? (
                            <img
                              className="sm:h-[50px] lg:h-[100px] max-w-[100px] image"
                              src={`${process.env.REACT_APP_API_URL}/storage/${item.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="sm:h-[50px] lg:h-[100px] max-w-25 image"
                              src={none}
                              alt=""
                            />
                          )}
                        </td>
                        <td className="px-6 py-4 max-w-[40%] text-sm text-gray-800 whitespace-nowrap">
                          {item.name}
                        </td>
                        <td className="px-6 py-4 max-w-[40%] text-sm text-gray-800 whitespace-nowrap">
                          {item.description}
                        </td>
                        <td className="px-6 py-4 max-w-[40%] text-sm text-gray-800 whitespace-nowrap">
                          {item.price}
                        </td>

                        <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                          <button
                            onClick={e => editGallery(e, item.id)}
                            className="text-green-500 hover:text-red-700 mr-3"
                            href="#"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete(item.id)}
                            className="text-red-500 hover:text-red-700"
                            href="#"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
