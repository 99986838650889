import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const STATISTICS = createAsyncThunk(
  "dashboard/browse",
  async (query, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/dashboard${
          query && query.year && `?year=${query.year}`
        }${query && query.month && `&month=${query.month}`}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const FAQS = createAsyncThunk(
  "dashboard/faqs",
  async (year, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/faqs`
        // {
        //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        // }
      );
      return resp.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const dashboardSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    collection: [],
    annual_sales: 0,
    annual_sold: 0,
    monthly_sales: 0,
    monthly_sold: 0,
    daily: 0,
  },
  extraReducers: {
    [STATISTICS.pending]: state => {
      console.log("loading");
    },
    [STATISTICS.fulfilled]: (state, action) => {
      console.log("fulfilled");
      state.items = action.payload.data;
      state.annual_sales = action.payload.annual_sales;
      state.annual_sold = action.payload.annual_sold;
      state.monthly_sales = action.payload.monthly_sales;
      state.monthly_sold = action.payload.monthly_sold;
      state.daily = action.payload.daily;
    },
    [STATISTICS.rejected]: state => {
      console.log("rejected");
    },
    [FAQS.pending]: state => {
      console.log("loading");
    },
    [FAQS.fulfilled]: (state, action) => {
      console.log("fulfilled");
      state.collection = action.payload;
    },
    [FAQS.rejected]: state => {
      console.log("rejected");
    },
  },
});

export const cartActions = dashboardSlice.actions;

export default dashboardSlice;
