import React, { useState } from "react";
import { useEffect } from "react";
import { FaBox } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PaymentModal from "../components/PaymentModal";
import { deleteCakeOnCart, fetchUsersCake } from "../store/custom-cake-slice";
import {
  deleteOnCart,
  getQtyEachUserOrder,
  getTotalPriceAllItems,
  getUserCart,
  orderActions,
  updateOrder,
  updateOrderIfExist,
} from "../store/order-slice";
import AlertModal from "../components/AlertModal";
import { FaSkullCrossbones } from "react-icons/fa";
import { useAuth } from "../App";
import Swal from "sweetalert2";
import qr from "../assets/img/qr.PNG";

const Cart = () => {
  const token = useAuth();
  const headers = [
    "",
    "Product",
    "Name",
    "Unit Price",
    "Quantity",
    "Total Price",
    "Status",
  ];

  const [status, setStatus] = useState("onCart");
  const [isHalf, setIsHalf] = useState(false);
  const addHeaderActions = ["Actions"];

  let header = [];
  if (status === "onCart" || status === "Ready-For-Delivery") {
    header = [...headers, addHeaderActions];
  } else header = [...headers];

  const dispatch = useDispatch();

  // const [isChecked, setiIsChecked] = useState(false);

  const {
    showModal,
    usersCart,
    getItemsByStatus,
    totalPrice,
    err,
    deliveryDetails,
    toBeCheckout,
    qtyEachUserOrder,
    isLoading,
  } = useSelector(state => state.order);

  const { usersCakes, cakeItems } = useSelector(state => state.customCake);

  useEffect(() => {
    dispatch(getQtyEachUserOrder());
    dispatch(getUserCart(status));
    dispatch(fetchUsersCake(status));
  }, [status, dispatch]);

  const cartDelete = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteOnCart(id));
        dispatch(getQtyEachUserOrder(token));
      }
    });
  };

  const cakeCartDelete = (e, id) => {
    e.preventDefault();
    dispatch(deleteCakeOnCart(id));
  };

  const navigate = (e, status) => {
    setStatus(status);
    dispatch(getUserCart(status));
    dispatch(fetchUsersCake(status));
    dispatch(orderActions.setTotalPrice({ totalPrice: 0, checked: "zero" }));
    if (status !== "onCart") dispatch(getTotalPriceAllItems(status));
  };

  const cartItems = status === "onCart" ? usersCart : getItemsByStatus;
  const customCakeItems = status === "onCart" ? usersCakes : cakeItems;

  const checkboxOnChange = e => {
    const { id, checked } = e.target;
    const item = usersCart.find(item => item.id === parseInt(id));

    dispatch(
      orderActions.setTotalPrice({ totalPrice: item.total_price, checked })
    );

    dispatch(orderActions.setToBeCheckout({ item, checked }));
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    dispatch(orderActions.setDeliveryDetails({ name, value }));
  };

  const checkout = async () => {
    if (
      !deliveryDetails.delivery_date ||
      // !deliveryDetails.delivery_address ||
      toBeCheckout.length === 0
    ) {
      dispatch(orderActions.setErr(true));
    } else {
      // dispatch(orderActions.setShowModal(true));
      for (let index = 0; index < toBeCheckout.length; index++) {
        const item = toBeCheckout[index];
        await Swal.fire({
          title: `Send to exact amount of  ${
            isHalf ? item.total_price / 2 : item.total_price
          }`,
          html: `
          <img src=${qr} class="w-[70%] mx-auto">
          <h3>Amount ${isHalf ? item.total_price / 2 : item.total_price}</h3>
        
           <input id="ref_no" class="swal2-input" type="text" placeholder="Reference Number" maxlength="13" oninput="this.value=this.value.replace(/[^0-9]/g, '').slice(0, this.maxLength)">`,
          focusConfirm: true,
          preConfirm: () => {
            const ref_no = Swal.getPopup()
              .querySelector("#ref_no")
              .value.toString();

            if (!ref_no || ref_no.length != 13) {
              Swal.showValidationMessage("Please enter 13 digit number");
            } else {
              dispatch(
                updateOrder({
                  id: item.id,
                  status: "Paid",
                  paid: isHalf ? item.total_price / 2 : item.total_price,
                  ref_no: ref_no,
                  unit_price: item?.unit_price,
                  delivery_date: deliveryDetails.delivery_date,
                  // delivery_address: deliveryDetails.delivery_address,
                })
              );
            }
          },
        });
      }

      dispatch(
        orderActions.setTotalPrice({
          totalPrice: 0,
          checked: "zero",
        })
      );
      dispatch(getQtyEachUserOrder(token));
      dispatch(orderActions.resetDeliveryDetails());
      dispatch(getUserCart(status));
      dispatch(orderActions.setShowModal(false));
    }
  };

  const delivered = (e, id) => {
    e.preventDefault();
    console.log(id);
    dispatch(
      updateOrder({
        id: id,
        status: "Completed",
      })
    );
    dispatch(orderActions.setTotalPrice({ totalPrice: 0, checked: "zero" }));
    dispatch(getUserCart(status));
    dispatch(getQtyEachUserOrder(token));
  };

  const handleQnty = (id, isAdd) => {
    if (isAdd) {
      dispatch(updateOrderIfExist({ product_id: id, quantity: 1 }));
    } else {
      dispatch(updateOrderIfExist({ product_id: id, quantity: -1 }));
    }
  };

  return (
    <>
      {showModal && <PaymentModal />}

      {err && (
        <AlertModal
          icon={<FaSkullCrossbones className="text-red-500 text-4xl" />}
          status={"Error"}
          message={"Please enter delivery date."}
          button={"Okay"}
          actions={orderActions}
        />
      )}

      <div className="md:w-full md:pr-10 md:pl-5 h-screen my-12 overflow-y-auto">
        <div className="flex items-center font-bold cursor-pointer text-xl mb-3 ml-3">
          <span className="mr-1 text-indigo-500">
            <FaBox />
          </span>
          <p>Shopping Cart</p>
        </div>

        <ul className="flex justify-between items-center px-2 text-center">
          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "onCart" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "onCart") || 0}
          >
            On Cart ({qtyEachUserOrder.oncart})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Paid" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Paid") || 0}
          >
            Paid ({qtyEachUserOrder.paid || 0})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Processing" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Processing")}
          >
            Processing ({qtyEachUserOrder.process || 0})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Ready-For-Delivery" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Ready-For-Delivery")}
          >
            Delivery ({qtyEachUserOrder.deliver || 0})
          </li>

          <li
            className={`py-2 px-4 border-l w-full cursor-pointer hover:bg-indigo-500 hover:text-white ${
              status === "Completed" ? "bg-indigo-500 text-white" : ""
            }`}
            onClick={e => navigate(e, "Completed")}
          >
            Completed ({qtyEachUserOrder.completed || 0})
          </li>
        </ul>

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="my-6 ml-2 flex">
              <div className="mr-3">
                <label htmlFor="">Delivery date: </label>
                <input
                  type="datetime-local"
                  className="border px-3 py-2"
                  onChange={handleChange}
                  value={deliveryDetails.delivery_date}
                  name="delivery_date"
                  min={new Date(new Date().getTime() + 259200000)
                    .toISOString()
                    .split(".")[0]
                    .split(":")
                    .splice(0, 2)
                    .join(":")}
                  required
                />
              </div>
            </div>
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {header.map((item, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 relative">
                    {cartItems?.map((item, index) => (
                      <tr key={index}>
                        {status === "onCart" ? (
                          item?.product?.deleted_at ? (
                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                              This item <br />
                              no longer <br />
                              available
                            </td>
                          ) : (
                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                              <input
                                className={`px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap
                                            ${
                                              item.type === "customized-cake" &&
                                              item.unit_price === 0
                                                ? "hidden"
                                                : "block"
                                            }`}
                                type="checkbox"
                                name="checkbox"
                                // value={isChecked}
                                id={item.id}
                                onChange={checkboxOnChange}
                              />
                            </td>
                          )
                        ) : (
                          <td></td>
                        )}
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/storage/${
                              item.image || item.product.image
                            }`}
                            className="w-24 h-24"
                            alt="product"
                          />
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 font-bold whitespace-nowrap">
                          {item.type === "normal"
                            ? item?.product?.name
                            : item.type}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm whitespace-nowrap ${
                            item.type === "customized-cake" &&
                            item.unit_price === 0
                              ? "text-red-500 font-bold"
                              : "text-gray-800"
                          }`}
                        >
                          {item.unit_price}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {status === "onCart" ? (
                            <>
                              {item.quantity > 1 && (
                                <button
                                  onClick={() =>
                                    handleQnty(
                                      item.product_id || item.id,
                                      false
                                    )
                                  }
                                  className="px-3 mx-2 bg-gray-200 btn"
                                >
                                  -
                                </button>
                              )}
                              {item.quantity}

                              <button
                                onClick={() =>
                                  handleQnty(item.product_id || item.id, true)
                                }
                                className="px-3 mx-2 bg-gray-200 btn"
                              >
                                +
                              </button>
                            </>
                          ) : (
                            item.quantity
                          )}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.total_price}
                        </td>
                        <td className="px-6 py-4 text-sm text-yellow-500 whitespace-nowrap">
                          {item.status === "Paid"
                            ? "For Confirmation"
                            : item.status}
                        </td>
                        {status === "onCart" && (
                          <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                            <button
                              onClick={e => cartDelete(e, item.id)}
                              className="text-red-500 hover:text-red-700"
                              href="#"
                            >
                              Cancel
                            </button>
                          </td>
                        )}
                        {status === "Ready-For-Delivery" && (
                          <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                            <button
                              onClick={e => delivered(e, item.id)}
                              className="bg-violet-500 py-3 px-4 text-white"
                              href="#"
                            >
                              Delivered
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}

                    {customCakeItems?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/storage/${item.image}`}
                            className="w-24 h-24"
                            alt="product"
                          />
                        </td>
                        <td className="px-6 py-4 text-sm text-indigo-800 font-bold whitespace-nowrap">
                          Customize
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.price ? item.price : "0"}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.quantity}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.price * item.quantity}
                        </td>
                        <td className="px-6 py-4 text-sm text-yellow-500 whitespace-nowrap">
                          {item.status}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium whitespace-nowrap">
                          <button
                            onClick={e => cakeCartDelete(e, item.id)}
                            className="text-red-500 hover:text-red-700"
                            href="#"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="float-right mt-3">
                {status !== "Completed" && (
                  <p className="mr-2 mb-2">
                    Total:{" "}
                    <span className="text-red-500">
                      ₱{(isHalf ? totalPrice / 2 : totalPrice) || 0}
                    </span>
                  </p>
                )}

                {status === "onCart" &&
                  (isLoading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          onChange={e => setIsHalf(e.target.checked)}
                          type="checkbox"
                          className="sr-only peer"
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                          {isHalf ? "Half payment" : "Full Payment"}
                        </span>
                      </label>
                      <button
                        onClick={checkout}
                        className="bg-indigo-500 text-white py-2 rounded px-14"
                      >
                        Checkout
                      </button>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
