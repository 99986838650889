import cake1 from "../assets/img/3.jpg";
import cake2 from "../assets/img/4.jpg";
import cake3 from "../assets/img/11.jpg";
import cake4 from "../assets/img/22.jpg";

export const customCakes = [
  {
    description: "null",
    id: 5,
    image: cake1,
    name: "Custom Cake 1",
    price: "0.00",
    type: "custom",
  },
  {
    description: "null",
    id: 5,
    image: cake2,
    name: "Custom Cake 2",
    price: "0.00",
    type: "custom",
  },
  {
    description: "null",
    id: 5,
    image: cake3,
    name: "Custom Cake 3",
    price: "0.00",
    type: "custom",
  },
  {
    description: "null",
    id: 5,
    image: cake4,
    name: "Custom Cake 4",
    price: "0.00",
    type: "custom",
  },
];
