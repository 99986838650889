import React from "react";
import bg from "../assets/img/banner.jpg";
import aboutImg from "../assets/img/aboutUs.png";

const TermsAndConditions = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto">
          <div className="h-screen flex items-center justify-center">
            <div className="md:flex items-center justify-center gap-3">
              <div className="bg-white rounded p-12 self-start md:basis-3/6 opacity-90">
                <h1 className="font-thin text-3xl text-violet-500 mb-5">
                  Terms And Conditions
                </h1>
                <p className="mb-3 font-bold">
                  Our Terms and Conditions for cake orders include the
                  following:
                </p>
                <ol className="list-decimal">
                  <li>
                    Orders must be placed through our website, fifty percent
                    (50%) payment required at the time of purchase.
                    Customization requests should be clearly communicated
                  </li>
                  <li>
                    Delivery is available within cavite city with the minimum of
                    500 pesos worth of product and free delivery for customize
                    cake, and accurate delivery information must be provided.
                  </li>
                  <li>
                    If the purchase are below 500 the customer must cater the
                    delivery fee.
                  </li>
                  <li>
                    Once placed, orders cannot be canceled or modified, and we
                    do not offer refunds for canceled orders.
                  </li>
                  <li>
                    We assure the quality of our cakes but do not provide
                    refunds due to our no-refund policy.
                  </li>
                </ol>
              </div>
              <div className="flex justify-center mb-3 md:mb-0 md:basis-3/6">
                <img src={aboutImg} alt="aboutUs" className="w-3/4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
