import React, { useEffect } from "react";
import { motion } from "framer-motion";
import TextBox from "./TextBox";
import { useDispatch, useSelector } from "react-redux";
import { saveUser, updateUser } from "../store/user-slice";
import { saveProduct, updateProduct } from "../store/product-slice";
import { useState } from "react";
import { FaMoneyBillAlt, FaRulerVertical } from "react-icons/fa";

const FormModal = ({
  addTitle,
  updateTitle,
  fields,
  actions,
  form,
  edit,
  isProduct = false,
}) => {
  const dispatch = useDispatch();
  const { errors } = useSelector(state => state.user);
  const [img, setImg] = useState(undefined);
  const [sizes, setSizes] = useState([]);
  const [prices, setPrices] = useState(
    form.sizes ? JSON.parse(form.sizes) : [{ size: "", price: 0 }]
  );

  useEffect(() => {
    const types = {
      cakes: ['8"', '10"', '12"'],
      beverages: ["12oz", "16oz", "22oz"],
      pastries: ["6's", "12's", "24's"],
    };
    if (form.type) {
      setSizes(types[form.type.toLowerCase()]);
    }
  }, [form]);

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(actions.setForm({ name, value }));
  };

  const closeModal = () => {
    dispatch(actions.setShowModal(false));
    dispatch(actions.setEdit(false));
    dispatch(actions.resetForm());
    dispatch(actions?.resetErrors());
  };

  const formData = new FormData();

  const getFile = async e => {
    setImg(e.target.files[0]);
  };

  const save = async e => {
    e.preventDefault();
    if (!edit) {
      try {
        if (addTitle.includes("User")) await dispatch(saveUser(form));
        else if (addTitle.includes("Product")) {
          Object.keys(form).map(item => {
            return item !== "image"
              ? formData.append(item, form[item])
              : formData.append("image", img);
          });
          formData.append("enctype", "multipart/form-data");
          formData.append("sizes", JSON.stringify(prices));
          dispatch(saveProduct(formData));

          dispatch(actions.resetErrors());
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (addTitle.includes("User")) dispatch(updateUser(form));
      else if (addTitle.includes("Product")) {
        Object.keys(form).map(item => {
          return item !== "image"
            ? formData.append(item, form[item])
            : formData.append("image", img);
        });

        formData.append("enctype", "multipart/form-data");
        formData.append("_method", "PUT");
        formData.append("sizes", JSON.stringify(prices));
        dispatch(updateProduct(formData));

        dispatch(actions.resetForm());
      }
    }
  };

  const update = () => {
    if (addTitle.includes("User")) dispatch(updateUser(form));
    else if (addTitle.includes("Product")) {
      Object.keys(form).map(item => {
        return item !== "image"
          ? formData.append(item, form[item])
          : formData.append("image", img);
      });

      formData.append("enctype", "multipart/form-data");
      formData.append("_method", "PUT");
      formData.append("sizes", JSON.stringify(prices));
      dispatch(updateProduct(formData));

      dispatch(actions.resetForm());
    }
  };

  const handleSelect = (e, dropdownType = "Type") => {
    const value = e.target.value;
    if (addTitle.includes("User")) dispatch(actions.updateRole(value));
    else if (addTitle.includes("Product"))
      dispatch(
        dropdownType === "Type"
          ? actions.setType(value)
          : actions.setIsBestSeller(value)
      );
  };

  const handleAddSize = () => {
    setPrices([...prices, { size: "", price: 0 }]);
  };

  const handlePrices = (e, index) => {
    const { name, value } = e.target;
    let newPrices = [...prices];
    newPrices[index][name] = value;
    setPrices(newPrices);
  };

  const handleRemoveSize = index => {
    let newPrices = [...prices];
    newPrices.splice(index, 1);
    setPrices(newPrices);
  };

  const handleSizeOption = type => {
    const types = {
      cakes: ['8"', '10"', '12"'],
      beverages: ["12oz", "16oz", "22oz"],
      pastries: ["6's", "12's", "24's"],
    };
    setSizes(types[type.toLowerCase() || form.type.toLowerCase()]);
  };

  return (
    <div className="bg-zinc-200 opacity-90 fixed inset-0 z-50">
      <div className="flex justify-center items-center h-screen">
        <motion.div
          initial={{ y: "-1000px" }}
          animate={{ y: "-50px" }}
          transition={{ type: "spring", duration: 0.5 }}
          className="bg-white rounded-xl py-6 px-12 flex flex-col justify-center w-4/12"
        >
          <p className="font-bold text-2xl text-gray-900 mb-3">
            {edit ? updateTitle : addTitle}
          </p>

          <div>
            <form onSubmit={save}>
              {fields.map((item, index) => (
                <div key={index} className="mb-2">
                  {!item.file && !item.dropdown && !item.options && (
                    <TextBox
                      required
                      type={item?.type}
                      placeholder={item.ph}
                      icon={item.icon}
                      field={item.field}
                      value={form[item.field]}
                      handleChange={handleChange}
                      errorMsg={errors[item.field] ? errors[item.field] : ""}
                    />
                  )}

                  {item.file && (
                    <input
                      required={!edit}
                      type="file"
                      placeholder="Upload Image"
                      name="image"
                      onChange={e => getFile(e)}
                      accept="image/png, image/gif, image/jpeg"
                      className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                    />
                  )}

                  {item.dropdown && (
                    <select
                      required
                      name="role"
                      value={form.field || form.type}
                      onChange={e => {
                        handleSizeOption(e.target.value);
                        handleSelect(e);
                      }}
                      className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                    >
                      <option value="">Select {item.ph}</option>
                      {item.dropdown.map((data, id) => (
                        <option key={id} value={data}>
                          {data}
                        </option>
                      ))}
                    </select>
                  )}

                  {item.options && (
                    <select
                      required
                      name={item.field}
                      value={form.field || form.is_best_seller}
                      onChange={e => handleSelect(e, item.ph)}
                      className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                    >
                      <option value="">{item.ph}</option>
                      {item.options.map((data, id) => (
                        <option key={id} value={id}>
                          {data}
                        </option>
                      ))}
                    </select>
                  )}

                  {item.dropdown === "dropdown" && (
                    <select
                      required
                      name="cars"
                      id="cars"
                      className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                    >
                      <option value="">Select Status</option>
                      <option value="Paid">Paid</option>
                      <option value="Pending">Pending</option>
                      <option value="Completed">Completed</option>
                    </select>
                  )}
                </div>
              ))}
              {form.type === "Cakes" && (
                <p className="bg-yellow-200 p-2 rounded mb-2">
                  10" and 12" are made to order
                </p>
              )}
              {isProduct &&
                prices.map((data, index) => {
                  return (
                    <div
                      className={`grid grid-cols-${
                        prices.length - 1 === index ? "2" : "3"
                      } gap-4 mb-2`}
                    >
                      <select
                        required
                        name="size"
                        value={data.size}
                        onChange={e => {
                          handlePrices(e, index);
                        }}
                        className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                      >
                        <option value="">
                          {form.type ? "Select size" : "Select type first"}
                        </option>
                        {sizes.map((data, id) => (
                          <option key={id} value={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                      {/* <TextBox
                        required
                        icon={<FaRulerVertical />}
                        type="text"
                        placeholder="Size(ex: sm, md, lg)"
                        field="size"
                        value={data.size}
                        handleChange={e => handlePrices(e, index)}
                      /> */}
                      <TextBox
                        required
                        icon={<FaMoneyBillAlt />}
                        type="number"
                        placeholder="Price"
                        field="price"
                        value={data.price}
                        handleChange={e => handlePrices(e, index)}
                      />
                      {prices.length - 1 === index ? (
                        <button
                          type="button"
                          className="py-2 px-4 bg-indigo-500 text-white rounded col-span-2"
                          onClick={() => handleAddSize(index)}
                        >
                          Add size
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="py-2 px-4 bg-red-400 text-white rounded"
                          onClick={() => handleRemoveSize()}
                        >
                          Remove size
                        </button>
                      )}
                    </div>
                  );
                })}
              {isProduct && (
                <select
                  required
                  name={"isSpecial"}
                  value={form.isSpecial}
                  onChange={handleChange}
                  className="text-gray-500 border py-3 pr-3 pl-10 rounded-md shadow-md w-full focus:outline-none"
                >
                  {isProduct}
                  <option value="" selected>
                    Special Cake
                  </option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              )}
              <div className="text-right mt-3">
                <button
                  onClick={closeModal}
                  className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                >
                  Close
                </button>
                {!edit ? (
                  <button
                    className="bg-indigo-500 text-white py-2 px-4 rounded"
                    // onClick={save}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    // onClick={update}
                    className="bg-indigo-500 text-white py-2 px-4 rounded"
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default FormModal;
