import { useEffect, useState } from "react";
import React from "react";
import Slider from "react-slick";
import { fetchProducts } from "../store/product-slice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import none from "../assets/img/none.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick-theme.css";
import banner from "../assets/img/banner.jpg";
import "../custom.css";

const Showcase = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const { allProducts } = useSelector(state => state.product);
  useEffect(() => {
    dispatch(fetchProducts("All"));
  }, [dispatch]);
  useEffect(() => {
    setProducts(allProducts.filter(p => p.isSpecial === 1));
    console.log(products);
  }, [allProducts]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <div
      className=" slider-container bg-[#B291BE] "
      style={{
        backgroundImage: `url(${banner})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Slider {...settings}>
        {products.map(item => (
          <Link to={`/product/${item.id}`} className="">
            <div key={item.id} className="w-full">
              {item.image ? (
                <img
                  className="h-[85vh] w-full image mx-auto"
                  src={`${process.env.REACT_APP_API_URL}/storage/${item.image}`}
                  alt=""
                />
              ) : (
                <img src={none} alt="" />
              )}
              {/* <div className="w-full bg-green-200">23</div> */}
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default Showcase;
