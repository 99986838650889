import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./Calendar.css";

import { getAllOrders, orderActions, updateOrder } from "../store/order-slice";

const Calendar = () => {
  const dispatch = useDispatch();

  const { allOrders } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(getAllOrders("all"));
  }, [dispatch]);

  const events = [];
  const data = {
    "customized-cake": "bg-[#eab308]",
    Cakes: "bg-[#22c55e]",

    Beverages: "bg-[#67e8f9]",

    Pastries: "bg-[#431407]",
  };
  const groupedCounts = allOrders
    .filter(e => e.status === "Completed" || e.status === "Ready-For-Delivery")
    .reduce((acc, obj) => {
      const key = obj.product_type + "|" + obj.delivery_date;
      const name = obj.product_type;
      const date = obj.delivery_date;

      if (!acc[key]) {
        acc[key] = { type: key, count: 0, name, date };
      }
      acc[key].count++;
      return acc;
    }, {});

  const result = Object.values(groupedCounts);
  console.log(result);
  result.map((item, index) => {
    let order_info = {
      classNames: [data[item.name]],
      title: item.name + " " + item.count,
      date: item.date,
      description: item.name,
    };
    // let order_info = {title: item.status};
    events.push(order_info);
  });
  console.log(events);

  return (
    <>
      <div className=" md:pr-10 md:pl-5 md:w-9/12 mt-3 md:mt-0 h-screen">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden rounded-lg calendar-view">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  headerToolbar={{
                    left: "prev",
                    center: "title",
                    right: "next",
                  }}
                  initialView="dayGridMonth"
                  weekends={true}
                  events={events}
                  eventDisplay="list-item"
                  contentHeight="70vh"
                  // aspectRatio= '2'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
